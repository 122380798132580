const company = {
  edges: [
    {
      arrows: '{to: true}',
      from: 20,
      id: 24,
      label: '股东',
      marprid: '252000000000002049',
      to: 57,
      type: 12
    },
    {
      arrows: '{to: true}',
      from: 20,
      id: 25,
      label: '股东',
      marprid: '252000000000002049',
      to: 58,
      type: 12
    },
    {
      arrows: '{to: true}',
      from: 20,
      id: 35,
      label: '100.000000',
      to: 81,
      type: 15
    },
    {
      arrows: '{to: true}',
      from: 20,
      id: 27,
      label: '股东',
      marprid: '252000000000002049',
      to: 59,
      type: 12
    },
    {
      arrows: '{to: true}',
      from: 20,
      id: 30,
      label: '高管',
      marprid: '252000000000002049',
      to: 21,
      type: 13
    },
    {
      arrows: '{to: true}',
      from: 20,
      id: 36,
      label: '100.000000',
      to: 82,
      type: 15
    },
    {
      arrows: '{to: true}',
      from: 20,
      id: 37,
      label: '60.000000',
      to: 83,
      type: 15
    },
    {
      arrows: '{to: true}',
      from: 20,
      id: 32,
      label: '高管',
      marprid: '252000000000002049',
      to: 62,
      type: 13
    },
    {
      arrows: '{to: true}',
      from: 20,
      id: 33,
      label: '高管',
      marprid: '252000000000002049',
      to: 63,
      type: 13
    },
    {
      arrows: '{to: true}',
      from: 20,
      id: 31,
      label: '高管',
      marprid: '252000000000002049',
      to: 58,
      type: 13
    },
    {
      arrows: '{to: true}',
      from: 20,
      id: 5,
      label: '法人',
      marprid: '252000000000002049',
      to: 21,
      type: 11
    },
    {
      arrows: '{to: true}',
      from: 20,
      id: 26,
      label: '股东',
      marprid: '252000000000002049',
      to: 21,
      type: 12
    },
    {
      arrows: '{to: true}',
      from: 20,
      id: 28,
      label: '股东',
      marprid: '252000000000002049',
      to: 60,
      type: 12
    }
  ],
  nodes: [
    {
      companyId: '520100017270991942',
      entName: '子公司1',
      id: 83,
      label: '子公司1',
      namePreapprId: '520100017228260669',
      regcap: '500.000000',
      type: 5
    },
    {
      companyId: '252000000000363071',
      entName: '子公司2',
      id: 81,
      label: '子公司2',
      namePreapprId: '2520000000004560025',
      regcap: '500.000000',
      type: 5
    },
    {
      cerNo: '320826196808081077',
      id: 21,
      label: '法人1',
      name: '法人1',
      type: 1
    },
    {
      cerNo: '',
      id: 57,
      label: '合伙1',
      name: '合伙1',
      type: 1
    },
    {
      cerNo: '210381197710024572',
      id: 59,
      label: '法人2',
      name: '法人2',
      type: 1
    },
    {
      cerNo: '52212519850919311X',
      id: 62,
      label: '法人3',
      name: '法人3',
      type: 1
    },
    {
      companyId: '252000000000002049',
      entName: '总公司',
      id: 20,
      label: '总公司',
      namePreapprId: '520100001362213793',
      regcap: '5000.000000',
      type: 5
    },
    {
      cerNo: '520112197806033229',
      id: 63,
      label: '法人4',
      name: '法人4',
      type: 1
    },
    {
      companyId: '520100017189367939',
      entName: '子公司3',
      id: 82,
      label: '子公司3',
      namePreapprId: '520100016977791268',
      regcap: '1000.000000',
      type: 5
    },
    {
      cerNo: '320826198202231017',
      id: 58,
      label: '法人5',
      name: '法人5',
      type: 1
    },
    {
      cerNo: '520102199003104042',
      id: 60,
      label: '法人6',
      name: '法人6',
      type: 1
    }
  ]
}
export {
  company
}
